import React from "react"
import * as styles from "./index.module.scss"
import Seo from "../../components/seo"
import Breadcrumbs from "../../components/Breadcrumbs"
import { BsBookmarkCheck, BsClock } from "react-icons/bs"
import { StaticImage } from "gatsby-plugin-image"
import { AiOutlineHome, AiOutlineShoppingCart } from "react-icons/ai"
import Layout from "../../Layout"
import Farmers from "./farmers.png"
import Farms from "./farms.png"
import Team from "./_team"

const AboutUsPage = () => {
  return (
    <Layout>
      <Seo title="About us" />
      <Breadcrumbs title={"About us"} />

      <div className="container">
        <div className="row">
          <div className="col-md-8 offset-md-2 col-xl-6 offset-xl-3 text-center mt-5 pt-5 mb-5">
            <StaticImage
              alt="Owita Organics"
              src={"../../images/logo_mobile.png"}
              placeholder="none"
              style={{ maxWidth: "255px" }}
            />
            <h2 className={"mt-5"}>Our food should be our medicine</h2>
            <p>
              Owita is the complete Organic and healthy food solution, which
              allows Sri Lankans to grow and eat Organic, Fresh and healthy
              food.
            </p>
          </div>

          <div className="col-sm-6 col-md-4 offset-md-2 text-center px-5">
            <img src={Farms} alt="" style={{ maxWidth: "128px" }} />
            <h3>50+</h3>
            <p>Acres of land cultivated with organic vegetables and fruits</p>
          </div>
          <div className="col-sm-6 col-md-4 text-center px-5">
            <img src={Farmers} alt="" style={{ maxWidth: "128px" }} />
            <h3>25+</h3>
            <p>Dedicated farmers cultivating certified organic food</p>
          </div>
        </div>
      </div>

      <div className={"line mt-5"}></div>

      <div className="container text-center mt-5">
        <div className="row">
          <div className="col-sm-12">
            <h2>Why us?</h2>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className={styles.round_icon}>
              <BsBookmarkCheck />
            </div>
            <h4>Certified Organic Food</h4>
            <p>
              All the items sold by Owita are made by the Certified Organic
              Farmers.
            </p>
          </div>

          <div className="col-sm-6 col-lg-3">
            <div className={styles.round_icon}>
              <AiOutlineHome />
            </div>
            <h4>Home Delivery</h4>
            <p>
              We provide Home Delivery Near Ragama. And hoping to serve the
              whole country in the future.
            </p>
          </div>

          <div className="col-sm-6 col-lg-3">
            <div className={styles.round_icon}>
              <AiOutlineShoppingCart />
            </div>
            <h4>Online Purchasing</h4>
            <p>You can order directly from the website.</p>
          </div>

          <div className="col-sm-6 col-lg-3">
            <div className={styles.round_icon}>
              <BsClock />
            </div>
            <h4>24x7 Open</h4>
            <p>Last but not least, We are open everyday...</p>
          </div>
        </div>
      </div>

      <div className="container text-center mt-5">
        <div className="row">
          <div className="col-sm-12">
            <h2>Our Team</h2>
            <Team />
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default AboutUsPage
